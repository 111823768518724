exports.components = {
  "component---src-pages-403-tsx": () => import("./../../../src/pages/403.tsx" /* webpackChunkName: "component---src-pages-403-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-ferramentas-gerador-de-assinatura-de-email-old-tsx": () => import("./../../../src/pages/ferramentas/gerador-de-assinatura-de-email-old.tsx" /* webpackChunkName: "component---src-pages-ferramentas-gerador-de-assinatura-de-email-old-tsx" */),
  "component---src-pages-ferramentas-gerador-de-assinaturas-index-tsx": () => import("./../../../src/pages/ferramentas/gerador-de-assinaturas/index.tsx" /* webpackChunkName: "component---src-pages-ferramentas-gerador-de-assinaturas-index-tsx" */),
  "component---src-pages-ferramentas-gerador-de-assinaturas-modelo-1-tsx": () => import("./../../../src/pages/ferramentas/gerador-de-assinaturas/modelo1.tsx" /* webpackChunkName: "component---src-pages-ferramentas-gerador-de-assinaturas-modelo-1-tsx" */),
  "component---src-pages-ferramentas-intype-index-tsx": () => import("./../../../src/pages/ferramentas/intype/index.tsx" /* webpackChunkName: "component---src-pages-ferramentas-intype-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-infraestrutura-cabeamento-estruturado-index-tsx": () => import("./../../../src/pages/infraestrutura/cabeamento-estruturado/index.tsx" /* webpackChunkName: "component---src-pages-infraestrutura-cabeamento-estruturado-index-tsx" */),
  "component---src-pages-lp-suporte-residencial-index-tsx": () => import("./../../../src/pages/lp/suporte-residencial/index.tsx" /* webpackChunkName: "component---src-pages-lp-suporte-residencial-index-tsx" */),
  "component---src-pages-personalize-sua-empresa-desenvolvimento-de-apps-index-tsx": () => import("./../../../src/pages/personalize-sua-empresa/desenvolvimento-de-apps/index.tsx" /* webpackChunkName: "component---src-pages-personalize-sua-empresa-desenvolvimento-de-apps-index-tsx" */),
  "component---src-pages-personalize-sua-empresa-desenvolvimento-de-lojas-virtuais-index-tsx": () => import("./../../../src/pages/personalize-sua-empresa/desenvolvimento-de-lojas-virtuais/index.tsx" /* webpackChunkName: "component---src-pages-personalize-sua-empresa-desenvolvimento-de-lojas-virtuais-index-tsx" */),
  "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sistemas-index-tsx": () => import("./../../../src/pages/personalize-sua-empresa/desenvolvimento-de-sistemas/index.tsx" /* webpackChunkName: "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sistemas-index-tsx" */),
  "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sites-index-tsx": () => import("./../../../src/pages/personalize-sua-empresa/desenvolvimento-de-sites/index.tsx" /* webpackChunkName: "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sites-index-tsx" */),
  "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sites-sc-dev-sites-tsx": () => import("./../../../src/pages/personalize-sua-empresa/desenvolvimento-de-sites/SCDevSites.tsx" /* webpackChunkName: "component---src-pages-personalize-sua-empresa-desenvolvimento-de-sites-sc-dev-sites-tsx" */),
  "component---src-pages-sc-index-page-tsx": () => import("./../../../src/pages/SCIndexPage.tsx" /* webpackChunkName: "component---src-pages-sc-index-page-tsx" */),
  "component---src-pages-sistemas-integrum-administracao-imobiliaria-tsx": () => import("./../../../src/pages/sistemas-integrum/administracao-imobiliaria.tsx" /* webpackChunkName: "component---src-pages-sistemas-integrum-administracao-imobiliaria-tsx" */),
  "component---src-pages-sobre-a-integrum-index-tsx": () => import("./../../../src/pages/sobre-a-integrum/index.tsx" /* webpackChunkName: "component---src-pages-sobre-a-integrum-index-tsx" */),
  "component---src-pages-suporte-em-ti-index-tsx": () => import("./../../../src/pages/suporte-em-ti/index.tsx" /* webpackChunkName: "component---src-pages-suporte-em-ti-index-tsx" */),
  "component---src-pages-suporte-em-ti-para-sua-casa-index-tsx": () => import("./../../../src/pages/suporte-em-ti/para-sua-casa/index.tsx" /* webpackChunkName: "component---src-pages-suporte-em-ti-para-sua-casa-index-tsx" */),
  "component---src-pages-suporte-em-ti-suporte-hibrido-index-tsx": () => import("./../../../src/pages/suporte-em-ti/suporte-hibrido/index.tsx" /* webpackChunkName: "component---src-pages-suporte-em-ti-suporte-hibrido-index-tsx" */),
  "component---src-pages-suporte-em-ti-suporte-local-index-tsx": () => import("./../../../src/pages/suporte-em-ti/suporte-local/index.tsx" /* webpackChunkName: "component---src-pages-suporte-em-ti-suporte-local-index-tsx" */)
}

